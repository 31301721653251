import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import * as _ from 'lodash';

import * as actionCreators from '../actions';

export default function TunerActionButtons({
  data,
  isLoading,
  isMassEdit,
  showOkCancel,
  showUndo,
  updateItem,
  setEditMode,
  massEditData
}) {
  const dispatch = useDispatch();
  const { saveSlottedItems } = bindActionCreators(actionCreators, dispatch);

  const onClickYes = () => {
    let postData = {};

    if (isMassEdit) {
      postData = { items: _.map(data, supc => ({ supc, type: 'confirm' })) };
    } else {
      postData = {
        items: [{ supc: data.supc, type: 'confirm' }]
      };
    }
    saveSlottedItems(postData);
  };

  const onClickNo = () => {
    if (isMassEdit) {
      setEditMode(true);
    } else {
      const { supc, bc, ig, ag, bc_name, ig_name, ag_name } = data;
      const updates = { initialValues: { bc, ig, ag, bc_name, ig_name, ag_name }, isEditing: true };
      updateItem(supc, updates);
    }
  };

  const onClickOk = () => {
    let postData = {};

    if (isMassEdit) {
      postData = {
        items: _.map(data, supc => {
          return {
            supc,
            bc: massEditData.bc,
            ig: massEditData.ig,
            ag: massEditData.ag,
            type: 'changed'
          };
        })
      };
    } else {
      postData = {
        items: [
          {
            supc: data.supc,
            bc: data.bc,
            ig: data.ig,
            ag: data.ag,
            type: 'changed'
          }
        ]
      };
    }
    saveSlottedItems(postData);
  };

  const onClickCancel = () => {
    if (isMassEdit) {
      setEditMode(false);
    } else {
      const { supc, initialValues } = data;
      const updates = { ...initialValues, initialValues: null, isEditing: false };
      updateItem(supc, updates);
    }
  };

  const onClickUndo = () => {
    saveSlottedItems({ items: [{ supc: data.supc, type: 'revert' }] });
  };

  if (showUndo) {
    return (
      <div className="action-btn-group">
        <Button
          className="auto-slotting-action-btn amber-btn"
          disabled={isLoading}
          loading={isLoading}
          onClick={onClickUndo}
        >
          {isLoading ? '' : 'UNDO'}
        </Button>
      </div>
    );
  } else if (showOkCancel) {
    return (
      <div className="action-btn-group">
        <Button
          className="auto-slotting-action-btn primary-btn"
          disabled={isLoading}
          loading={isLoading}
          onClick={onClickOk}
        >
          {isLoading ? '' : 'OK'}
        </Button>
        <Button className="auto-slotting-action-btn secondary-btn" disabled={isLoading} onClick={onClickCancel}>
          CANCEL
        </Button>
      </div>
    );
  } else {
    return (
      <div className="action-btn-group">
        <Button
          className="auto-slotting-action-btn primary-btn"
          disabled={isLoading}
          loading={isLoading}
          onClick={onClickYes}
        >
          {isLoading ? '' : 'YES'}
        </Button>
        <Button className="auto-slotting-action-btn secondary-btn" disabled={isLoading} onClick={onClickNo}>
          NO
        </Button>
      </div>
    );
  }
}
