import { put } from 'redux-saga/effects';
import { getRequest, postRequest } from '_http';
import { TAXONOMY, SLOTTEDLIST, SAVESLOTTEDITEM } from 'actions/actionTypes';
import { action } from 'reduxHelpers';
import * as _ from 'lodash';

function* fetchAutoSlottedItemListAsync({ filters, page, search, sort, filterItemsFor, selectedOpco }) {
  let filterArray = [];
  if (filters) {
    for (const [param, value] of Object.entries(filters)) {
      if (value) {
        let val = `${value}`;
        filterArray.push({ param, val });
      }
    }
  }
  let filterObj = { page, search };
  if (filterArray && filterArray.length > 0) {
    filterObj.filters = filterArray;
  }
  if (sort) {
    filterObj.sorting = sort;
  }
  filterObj.type = selectedOpco || filterItemsFor;
  try {
    const response = yield getRequest(`/items/${encodeURI(JSON.stringify(filterObj))}`);
    yield put({
      type: SLOTTEDLIST.SUCCESS,
      payload: { tableData: _.get(response, 'data', {}) }
    });
  } catch (error) {
    yield put({ type: SLOTTEDLIST.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH ITEM LIST"
      })
    );
  }
}

function* fetchTaxonomyAsync({ bc }) {
  try {
    let allRequest = false;
    try {
      const all = yield getRequest(`/bcs`);
      let allBcs = _.get(all, 'data', []);
      for (let x = 0; x < allBcs.length; x++) {
        yield put({
          type: TAXONOMY.SUCCESS,
          payload: { bc: allBcs[x].id, data: allBcs[x] }
        });
      }
      allRequest = true;
    } catch (e) {}
    if (!allRequest) {
      const response = yield getRequest(`/bcs/${bc}`);
      yield put({
        type: TAXONOMY.SUCCESS,
        payload: { bc, data: _.get(response, 'data', {}) }
      });
    }
  } catch (error) {
    yield put({ type: TAXONOMY.FAILURE, payload: { bc } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH TAXONOMY"
      })
    );
  }
}

function* saveSlottedItemsAsync({ postData }) {
  try {
    const response = yield postRequest(`/items`, postData);
    const items = _.get(response, 'data.items', []);
    const failed = _.filter(items, obj => !obj.saved);

    yield put({ type: SAVESLOTTEDITEM.SUCCESS, payload: { items } });

    if (!_.isEmpty(failed)) {
      let failedSupcs = _.map(failed, obj => obj.supc);
      yield put(
        action('SHOW_NOTIFICATION', {
          description: `${_.join(failedSupcs, ', ')}`,
          className: 'error',
          message: "COULDN'T SAVE CHANGES FOR SOME ITEMS"
        })
      );
    } else {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: 'CHANGES SAVED!',
          className: 'info',
          message: 'SUCCESS'
        })
      );
    }
  } catch (error) {
    yield put({ type: SAVESLOTTEDITEM.FAILURE, payload: { items: postData.items } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T SAVE CHANGES"
      })
    );
  }
}

export { fetchTaxonomyAsync, fetchAutoSlottedItemListAsync, saveSlottedItemsAsync };
