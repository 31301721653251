import { BUSINESS_CENTERS, DEFAULT_PAGE_SIZE } from './../../utils/Constants';

const slottedList = {
  tableData: {
    items: null,
    count: 0,
    page: 1,
    size: DEFAULT_PAGE_SIZE
  },
  taxonomy: BUSINESS_CENTERS,
  filters: {
    bc: null,
    ig: null,
    ag: null
  },
  search: null,
  sort: null,
  fetchingList: true,
  fetchingTaxonomies: [],
  massEdit: {
    selectedRows: [],
    savingMassEdits: false
  },
  filterItemsFor: null,
  selectedOpco: null
};

export default slottedList;
