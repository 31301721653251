import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Select } from 'antd';
import * as _ from 'lodash';

import * as actionCreators from '../actions';

const { Option } = Select;

export default function TaxonomyDropdown({ bc, ig, ag, isFilter, fetchingTaxonomies, handleChange }) {
  const dispatch = useDispatch();
  const { fetchTaxonomy } = bindActionCreators(actionCreators, dispatch);

  const taxonomy = useSelector(state => _.get(state.slottedList, 'taxonomy', []));

  const igTarget = _.find(taxonomy, obj => bc === obj.id);
  const igs = _.get(igTarget, 'igs', []);
  const agTarget = _.find(igs, obj => ig === obj.id);
  const ags = _.get(agTarget, 'ags', []);
  const isLoading = _.includes(fetchingTaxonomies, bc);

  useEffect(() => {
    if (bc !== '[NULL]') {
      fetchTaxonomyForCurrentBc();
    }
  }, [bc]);

  const fetchTaxonomyForCurrentBc = () => {
    const target = _.find(taxonomy, obj => bc === obj.id);
    if (bc && _.isEmpty(target?.igs)) {
      fetchTaxonomy(bc);
    }
  };

  const nullOption = (
    <Option value="[NULL]" key="1" style={{ opacity: '0.5' }}>
      -- NULL --
    </Option>
  );

  return (
    <div className="taxo-dropdown-group">
      <Select
        className="taxo-dropdown"
        value={bc}
        disabled={isLoading}
        onChange={(value, options) => handleChange('bc', value, options.children)}
        dropdownMatchSelectWidth={false}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        <Option value={null} key="0">
          {isFilter ? ' All Business Centers' : '---'}
        </Option>
        {_.map(taxonomy, obj => {
          return (
            <Option key={obj.id} value={obj.id}>
              {obj.name}
            </Option>
          );
        })}
        {isFilter && nullOption}
      </Select>

      <Select
        className="taxo-dropdown"
        value={isLoading ? null : ig}
        onChange={(value, options) => handleChange('ig', value, options.children)}
        disabled={isLoading || !bc}
        dropdownMatchSelectWidth={false}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        <Option value={null} key="0">
          {isLoading ? 'Loading...' : isFilter ? 'All Item Groups' : '---'}
        </Option>
        {_.map(igs, obj => {
          return (
            <Option key={obj.id} value={obj.id}>
              {obj.name}
            </Option>
          );
        })}
        {isFilter && nullOption}
      </Select>

      <Select
        className="taxo-dropdown"
        value={isLoading ? null : ag}
        onChange={(value, options) => handleChange('ag', value, options.children)}
        disabled={isLoading || !bc || !ig}
        dropdownMatchSelectWidth={false}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        <Option value={null} key="0">
          {isLoading ? 'Loading...' : isFilter ? ' All Attribute Groups' : '---'}
        </Option>
        {_.map(ags, obj => {
          return (
            <Option key={obj.id} value={obj.id}>
              {obj.name}
            </Option>
          );
        })}
        {isFilter && nullOption}
      </Select>
    </div>
  );
}
