import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Pagination, Checkbox, Select, DatePicker } from 'antd';
import * as _ from 'lodash';
import moment from 'moment';

import AutoSlottingTable from 'components/AutoSlottingTable';
import MassEditModal from 'components/MassEditModal';
import TaxonomyDropdown from 'components/TaxonomyDropdown';
import AppLoader from 'components/AppLoader';
import * as actionCreators from '../actions';
import {
  DEFAULT_PAGE_SIZE,
  ADMIN_PERMISSION,
  CANADIAN_PERMISSION,
  USBL,
  CANADA,
  ALL,
  OPCO_SELECTOR
} from 'utils/Constants';

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function AutoSlottingView() {
  const dispatch = useDispatch();
  const { fetchAutoSlottedList, changeFilters } = bindActionCreators(actionCreators, dispatch);

  const items = useSelector(state => _.get(state.slottedList, 'tableData.items', null));
  const count = useSelector(state => _.get(state.slottedList, 'tableData.count', 0));
  const page = useSelector(state => _.get(state.slottedList, 'tableData.page', 1));
  const search = useSelector(state => _.get(state.slottedList, 'search', ''));
  const sort = useSelector(state => _.get(state.slottedList, 'sort', {}));
  const size = useSelector(state => _.get(state.slottedList, 'tableData.size', DEFAULT_PAGE_SIZE));
  const fetchingList = useSelector(state => _.get(state.slottedList, 'fetchingList', false));
  const fetchingTaxonomies = useSelector(state => _.get(state.slottedList, 'fetchingTaxonomies', []));
  const filters = useSelector(state => _.get(state.slottedList, 'filters', {}));
  const { permissions } = useSelector(state => _.get(state.user, 'data', {}));
  const selectedOpco = useSelector(state => _.get(state.slottedList, 'selectedOpco', null));
  const filterItemsFor = useSelector(state => _.get(state.slottedList, 'filterItemsFor', null));

  const [businessCenter, setBusinessCenter] = useState(null);
  const [itemGroup, setItemGroup] = useState(null);
  const [attributeGroup, setAttributeGroup] = useState(null);
  const [alreadyFetchedInitialData, setInitialDataState] = useState(false);
  const [defaultOpco, setDefaultOpco] = useState(null);

  useEffect(() => {
    if (items === null && !alreadyFetchedInitialData && permissions && permissions.length > 0) {
      let opcoFilter = USBL;
      if (permissions.includes(CANADIAN_PERMISSION)) opcoFilter = CANADA;
      if (permissions.includes(ADMIN_PERMISSION)) opcoFilter = ALL;
      setInitialDataState(true);
      setDefaultOpco(opcoFilter);
      fetchAutoSlottedList({ filterItemsFor: opcoFilter });
    }
  }, [items, permissions, alreadyFetchedInitialData]);

  const handleDropDownChange = (type, id) => {
    if (type === 'bc') {
      setBusinessCenter(id);
      setItemGroup(null);
      setAttributeGroup(null);
      changeFilters({
        filters: { ...filters, bc: id, ig: null, ag: null },
        page: 0,
        search,
        sort,
        selectedOpco,
        filterItemsFor
      });
    } else if (type === 'ig') {
      setItemGroup(id);
      setAttributeGroup(null);
      changeFilters({ filters: { ...filters, ig: id, ag: null }, page: 0, search, sort, selectedOpco, filterItemsFor });
    } else if (type === 'ag') {
      setAttributeGroup(id);
      changeFilters({ filters: { ...filters, ag: id }, page: 0, search, sort, selectedOpco, filterItemsFor });
    }
  };

  const onChangePagination = newPage => {
    changeFilters({ filters, page: newPage - 1, search, sort, selectedOpco, filterItemsFor });
  };

  const onSearch = newSearch => {
    changeFilters({ filters, page: 0, search: newSearch, sort, selectedOpco, filterItemsFor });
  };

  const onSort = newSort => {
    changeFilters({ filters, page, search, sort: newSort, selectedOpco, filterItemsFor });
  };

  const onFilterChange = event => {
    if (event.name) {
      const { name, value } = event;
      let fChange = { [name]: value };
      changeFilters({ filters: { ...filters, ...fChange }, page: 0, search, sort, selectedOpco, filterItemsFor });
    }
  };

  const onClearFilter = () => {
    setBusinessCenter(null);
    setItemGroup(null);
    setAttributeGroup(null);
    changeFilters({
      filters: { bc: null, ig: null, ag: null },
      page: 0,
      search: '',
      sort,
      selectedOpco: null,
      filterItemsFor
    });
  };

  const getOpcoDropDownDisabledStatus = () => [CANADA, USBL].includes(defaultOpco);

  const handleOpcoChange = value => {
    changeFilters({ filters, page: 0, search, sort, selectedOpco: value, filterItemsFor });
  };

  if (fetchingList) {
    return <AppLoader show />;
  } else {
    return (
      <React.Fragment>
        <div className="main-wrapper">
          <div className="grid-filter-panel">
            <div
              className="back-to-dashboard"
              onClick={() => {
                window.location.href = '/suite/dashboard';
              }}
            />
            <div className="bread-crum-seperator"></div>
            <div className="title">Auto Slotting Review</div>
          </div>
          <div className="auto-slotting-filter-group">
            <div onClick={onClearFilter} className="clear-filters">
              Clear Filters
            </div>
            <Select
              defaultValue={selectedOpco || defaultOpco}
              onChange={value => handleOpcoChange(value)}
              className="main-filter opcos"
              dropdownMatchSelectWidth={true}
              disabled={getOpcoDropDownDisabledStatus()}
            >
              {OPCO_SELECTOR.map(({ label, value }) => {
                return (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                );
              })}
            </Select>
            <div className="sysco-check">
              <Checkbox
                onChange={event => {
                  onFilterChange({ name: 'sysco_brand_indicator', value: event.target.checked ? 'Y' : null });
                }}
                defaultChecked={filters && filters.sysco_brand_indicator === 'Y'}
              >
                Sysco
              </Checkbox>
            </div>
            <div className="main-filter">
              <RangePicker
                className="feedback-date-range-filter"
                format={'MM/DD'}
                style={{ width: '160px' }}
                onChange={e => {
                  onFilterChange({
                    name: 'created_at',
                    value: e ? `daterange|${e.map(d => d.format('YYYY-MM-DD')).join('|')}` : null
                  });
                }}
                value={
                  filters && filters.created_at
                    ? [moment(filters.created_at.split('|')[1]), moment(filters.created_at.split('|')[2])]
                    : null
                }
                allowClear={true}
              />
            </div>
            <Select
              defaultValue={filters && filters.curr_id ? filters.curr_id : null}
              onChange={value => {
                onFilterChange({ name: 'curr_id', value });
              }}
              className="main-filter"
              dropdownMatchSelectWidth={false}
            >
              <Option value={null}>All Confidences</Option>
              <Option value={'Y'}>Confident</Option>
              <Option value={'N'}>Suspect</Option>
            </Select>
            <Select
              defaultValue={filters && filters.confirmed ? filters.confirmed : null}
              onChange={value => {
                onFilterChange({ name: 'confirmed', value });
              }}
              className="main-filter"
              dropdownMatchSelectWidth={false}
            >
              <Option value={null}>All Statuses</Option>
              <Option value={'1'}>Reviewed</Option>
              <Option value={'0'}>Pending</Option>
            </Select>
            <TaxonomyDropdown
              bc={businessCenter}
              ig={itemGroup}
              ag={attributeGroup}
              isFilter={true}
              fetchingTaxonomies={fetchingTaxonomies}
              handleChange={handleDropDownChange}
            />
            <div className="auto-slotting-search">
              <Search placeholder="Search..." onSearch={onSearch} defaultValue={search} />
            </div>
          </div>
          <AutoSlottingTable sort={sort} onSort={onSort} items={items} page={page} count={count} pageSize={size} />
          <div className="page-footer">
            <div className="footer-btns-wrapper">
              <MassEditModal />
              <Pagination
                size="small"
                className="paginator-footer"
                onChange={onChangePagination}
                total={count}
                showTotal={total => `${total} rows`}
                current={page + 1}
                defaultPageSize={size}
                showQuickJumper
                showSizeChanger={false}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
