import React from 'react';
import * as _ from 'lodash';

import { ITEM_DETAILS_TABLE_HEADERS } from './../utils/Constants';

export default function ItemDetailsTable({ record }) {
  return (
    <div className="item-details-pane">
      {_.map(ITEM_DETAILS_TABLE_HEADERS, ({ name, id }) => {
        const value = _.get(record, `${id}`, 'N/A');

        return (
          <div key={id} className="record-box">
            <div className="record-title">{name}</div>
            <div className="record-des">{value}</div>
          </div>
        );
      })}
    </div>
  );
}
