import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import AutoSlottingView from './pages/AutoSlottingView';
import AppLoader from 'components/AppLoader';
import * as userActions from 'actions';

// TODO: Add the correct app logo
import AppLogo from './styles/images/app-logos/item-mgmt.svg';
import styles from './styles/main.scss';

const setAppLogo = () => {
  if (document.getElementById('app-logo')) {
    document.getElementById('app-logo').src = '/ui/autoslotting' + AppLogo;
  }
};

const setAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar app-mode';
  }
};

function MainApp({ fetching, actions, userId }) {
  setAppLogo();
  setAppHeaderToAppMode();

  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  useEffect(() => {
    actions.loadUserDetails(userId);
  }, [actions, userId]);

  if (!fetching) {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/suite/autoslotting" // TODO: Add the correct path
              component={AutoSlottingView}
            />
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  } else {
    return <AppLoader show />;
  }
}

function mapStateToProps(state) {
  return {
    fetching: false // ---------------- for the moment
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(userActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
