import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'antd';

import TaxonomyDropdown from 'components/TaxonomyDropdown';
import TunerActionButtons from 'components/TunerActionButtons';
import _ from 'lodash';

export default function MassEditModal() {
  const supcs = useSelector(state => _.get(state.slottedList, 'massEdit.selectedRows', []));
  const savingMassEdits = useSelector(state => _.get(state.slottedList, 'massEdit.savingMassEdits', false));
  const fetchingTaxonomies = useSelector(state => _.get(state.slottedList, 'fetchingTaxonomies', []));

  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [bc, setBc] = useState(null);
  const [ig, setIg] = useState(null);
  const [ag, setAg] = useState(null);

  useEffect(() => {
    if (_.isEmpty(supcs)) {
      setShowModal(false);
    }
  }, [supcs]);

  const toggleModal = () => {
    setEditMode(false);
    setBc(null);
    setIg(null);
    setAg(null);
    setShowModal(!showModal);
  };

  const handleDropDownChange = (type, id) => {
    if (type === 'bc') {
      if (id == null) {
        return false;
      }
      setBc(id);
      setIg(null);
      setAg(null);
    } else if (type === 'ig') {
      setIg(id);
      setAg(null);
    } else if (type === 'ag') {
      setAg(id);
    }
  };

  const renderModalBody = () => {
    const massEditData = { bc, ig, ag };
    const message = (
      <span>
        {supcs.length} item{supcs.length > 1 ? 's' : ''} selected. <br />
        <b>Is the slotting correct?</b>
      </span>
    );

    const actionButtons = (
      <div className="action-btns-wrapper">
        <TunerActionButtons
          data={supcs}
          isMassEdit={true}
          isLoading={savingMassEdits}
          showOkCancel={editMode}
          showUndo={false}
          setEditMode={setEditMode}
          massEditData={massEditData}
        />
      </div>
    );

    if (editMode) {
      return (
        <React.Fragment>
          <TaxonomyDropdown
            bc={bc}
            ig={ig}
            ag={ag}
            fetchingTaxonomies={fetchingTaxonomies}
            handleChange={(type, id) => handleDropDownChange(type, id)}
          />
          {actionButtons}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="mass-edit-body-text">{message}</div>
        {actionButtons}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Modal
        className="mass-edit-modal"
        title={'Mass Edit'}
        visible={showModal}
        maskClosable={false}
        closable={true}
        onCancel={toggleModal}
        footer={false}
        width={'680px'}
        bodyStyle={{ height: '200px' }}
      >
        {renderModalBody()}
      </Modal>

      <div className="mass-edit-wrapper">
        <Button className="mass-edit-btn" disabled={supcs.length < 1} onClick={toggleModal}>
          Mass Update
        </Button>
        <div className="mass-edit-note">Note: Cannot mass edit already confirmed items</div>
      </div>
    </React.Fragment>
  );
}
