import { takeLatest, takeEvery } from 'redux-saga/effects';
import { TAXONOMY, SLOTTEDLIST, FILTER, SAVESLOTTEDITEM } from 'actions/actionTypes';
import { fetchTaxonomyAsync, fetchAutoSlottedItemListAsync, saveSlottedItemsAsync } from './slottedListSaga';

const slottedListSaga = [
  takeEvery(TAXONOMY.REQUEST, fetchTaxonomyAsync),
  takeLatest(SLOTTEDLIST.REQUEST, fetchAutoSlottedItemListAsync),
  takeLatest(FILTER.REQUEST, fetchAutoSlottedItemListAsync),
  takeEvery(SAVESLOTTEDITEM.REQUEST, saveSlottedItemsAsync)
];

export default slottedListSaga;
