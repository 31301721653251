import { action } from 'reduxHelpers';
import { USER, TAXONOMY, SLOTTEDLIST, FILTER, UPDATESLOTTEDLIST, SAVESLOTTEDITEM, SELECTROW } from './actionTypes';

const loadUserDetails = userId => action(USER.REQUEST, { userId });
const fetchTaxonomy = bc => action(TAXONOMY.REQUEST, { bc });
const fetchAutoSlottedList = data => action(SLOTTEDLIST.REQUEST, { ...data });
const changeFilters = ({ filters, page, sort, search, selectedOpco, filterItemsFor }) =>
  action(FILTER.REQUEST, { filters, page, sort, search, selectedOpco, filterItemsFor });
const updateSlottedTable = updatedList => action(UPDATESLOTTEDLIST.REQUEST, { updatedList });
const saveSlottedItems = postData => action(SAVESLOTTEDITEM.REQUEST, { postData });
const setSelectedTableRows = supcs => action(SELECTROW.REQUEST, { supcs });

export {
  loadUserDetails,
  fetchTaxonomy,
  fetchAutoSlottedList,
  changeFilters,
  updateSlottedTable,
  saveSlottedItems,
  setSelectedTableRows
};
