import * as _ from 'lodash';
import initialState from 'store/initialState';
import { SLOTTEDLIST, TAXONOMY, FILTER, UPDATESLOTTEDLIST, SAVESLOTTEDITEM, SELECTROW } from 'actions/actionTypes';

const slottedList = (state = initialState.slottedList, action) => {
  switch (action.type) {
    case FILTER.REQUEST:
      return {
        ...state,
        fetchingList: true,
        filters: { ...action.filters },
        tableData: { ...state.tableData, page: action.page },
        search: action.search,
        sort: action.sort,
        selectedOpco: action.selectedOpco
      };
    case SLOTTEDLIST.REQUEST:
      return { ...state, fetchingList: true, filterItemsFor: action.filterItemsFor };
    case SLOTTEDLIST.SUCCESS:
      return { ...state, fetchingList: false, tableData: action.payload.tableData };
    case SLOTTEDLIST.FAILURE:
      return { ...state, fetchingList: false };
    case TAXONOMY.REQUEST:
      return {
        ...state,
        fetchingTaxonomies: updateTaxonomyLoadingStatus([...state.fetchingTaxonomies], action.bc, 'ADD')
      };
    case TAXONOMY.SUCCESS:
      const updatedTaxonomy = updateTaxonomy(action.payload.bc, [...state.taxonomy], action.payload.data);
      return {
        ...state,
        fetchingTaxonomies: updateTaxonomyLoadingStatus([...state.fetchingTaxonomies], action.payload.bc, 'REMOVE'),
        taxonomy: updatedTaxonomy
      };
    case TAXONOMY.FAILURE:
      return {
        ...state,
        fetchingTaxonomies: updateTaxonomyLoadingStatus([...state.fetchingTaxonomies], action.payload.bc, 'REMOVE')
      };
    case UPDATESLOTTEDLIST.REQUEST:
      return { ...state, tableData: { ...state.tableData, items: action.updatedList } };
    case SAVESLOTTEDITEM.REQUEST:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          items: updateItemsList([...state.tableData.items], action.postData.items)
        },
        massEdit: { ...state.massEdit, savingMassEdits: true }
      };
    case SAVESLOTTEDITEM.SUCCESS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          items: updateItemsList([...state.tableData.items], action.payload.items)
        },
        massEdit: {
          ...state.massEdit,
          savingMassEdits: false,
          selectedRows: updateSelectedSupcList([...state.massEdit.selectedRows], action.payload.items)
        }
      };
    case SAVESLOTTEDITEM.FAILURE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          items: updateItemsList([...state.tableData.items], action.payload.items)
        },
        massEdit: { ...state.massEdit, savingMassEdits: false }
      };
    case SELECTROW.REQUEST:
      return { ...state, massEdit: { ...state.massEdit, selectedRows: action.supcs } };
    default:
      return state;
  }
};

const sortTaxonomy = taxonomy => {
  let sorted = _.cloneDeep(taxonomy);
  sorted.igs = _.sortBy(sorted.igs, e => e.name);
  sorted.igs = sorted.igs.forEach(e => {
    e.ags = _.sortBy(e.ags, e => e.name);
  });
  return sorted;
};

const updateTaxonomy = (bc, taxonomy, newData) => {
  const index = _.findIndex(taxonomy, obj => bc === obj.id);

  if (index !== -1 && !_.isEmpty(newData.igs)) {
    const clone = { ...sortTaxonomy(taxonomy[index]), igs: newData.igs };
    taxonomy[index] = clone;
  }
  return taxonomy;
};

const updateTaxonomyLoadingStatus = (existingList, bc, operation) => {
  if (operation === 'ADD' && !_.includes(existingList, bc)) {
    existingList.push(bc);
  } else if (operation === 'REMOVE') {
    existingList = _.filter(existingList, id => id !== bc);
  }
  return existingList;
};

const updateItemsList = (currentList, updatedList) => {
  _.forEach(updatedList, obj => {
    let index = _.findIndex(currentList, elem => obj.supc === elem.supc);

    if (index !== -1) {
      let item = { ...currentList[index] };
      item.isLoading = !item.isLoading;

      if (obj.saved) {
        item = obj.saved;
      }

      currentList[index] = item;
    }
  });

  return currentList;
};

const updateSelectedSupcList = (currentList, items) => {
  let success = _.map(items, obj => {
    if (obj.saved) {
      return obj.supc;
    }
  });

  const result = _.filter(currentList, supc => !_.includes(success, supc));
  return result;
};

export default slottedList;
