import { createRequestTypes } from 'reduxHelpers';

const USER = createRequestTypes('USER');
const SLOTTEDLIST = createRequestTypes('SLOTTEDLIST');
const TAXONOMY = createRequestTypes('TAXONOMY');
const FILTER = createRequestTypes('FILTER');
const UPDATESLOTTEDLIST = createRequestTypes('UPDATESLOTTEDLIST');
const SAVESLOTTEDITEM = createRequestTypes('SAVESLOTTEDITEM');
const SELECTROW = createRequestTypes('SELECTROW');

export { USER, SLOTTEDLIST, TAXONOMY, FILTER, UPDATESLOTTEDLIST, SAVESLOTTEDITEM, SELECTROW };
