export const BUSINESS_CENTERS = [
  {
    id: 27,
    name: 'ADMINISTRATIVE'
  },
  {
    id: 10,
    name: 'BAKERY'
  },
  {
    id: 11,
    name: 'BEEF'
  },
  {
    id: 12,
    name: 'BEVERAGE'
  },
  {
    id: 13,
    name: 'CANNED'
  },
  {
    id: 14,
    name: 'CHEMICAL'
  },
  {
    id: 15,
    name: 'COMMODITIES'
  },
  {
    id: 16,
    name: 'CONVENIENCE'
  },
  {
    id: 17,
    name: 'DAIRY'
  },
  {
    id: 18,
    name: 'DISPOSABLES'
  },
  {
    id: 19,
    name: 'FROZEN FRUITS & VEGETABLES'
  },
  {
    id: 20,
    name: 'FROZEN POTATOES'
  },
  {
    id: 21,
    name: 'GROCERY'
  },
  {
    id: 22,
    name: 'PORK'
  },
  {
    id: 23,
    name: 'POULTRY'
  },
  {
    id: 24,
    name: 'PRODUCE'
  },
  {
    id: 25,
    name: 'SEAFOOD'
  },
  {
    id: 26,
    name: 'SUPPLIES & EQUIPMENT'
  }
];

export const DEFAULT_PAGE_SIZE = 15;

export const ITEM_DETAILS_TABLE_HEADERS = [
  { id: 'supc', name: 'Material Number' },
  { id: 'material_type', name: 'Material Type' },
  { id: 'item_description', name: 'Material Description' },
  { id: 'item_unabbreviated_description', name: 'Material Unabbreviated Description' },
  { id: 'gdsn', name: 'GDSN Controlled' },
  { id: 'mpc', name: 'Manufacture Product Code' },
  { id: 'pack', name: 'Pack' },
  { id: 'size', name: 'Size' },
  { id: 'size_uom', name: 'Size UOM' },
  { id: 'gtin', name: 'GTIN' },
  { id: 'product_hierarchy', name: 'Product Hierarchy' },
  { id: 'storage_code', name: 'Vendor Storage Code' },
  { id: 'total_shelf_life', name: 'Total Shelf Life' },
  { id: 'catman_corporate', name: 'Cat Man - Corporate' },
  { id: 'item_status', name: 'xplantmaterialstatus' },
  { id: 'brand_name', name: 'Brand Name' },
  { id: 'brand_code', name: 'Brand Code' },
  // { id: 'brand_status', name: 'Brand Status' },
  { id: 'corp_prop', name: 'Proprietary Corporate' },
  { id: 'sysco_brand_indicator', name: 'Sysco Brand' },
  { id: 'true_vendor_corporate_number', name: 'True Vendor Number' },
  { id: 'true_vendor_corporate_name', name: 'True Vendor Name' },
  { id: 'category', name: 'Category' },
  { id: 'category_description', name: 'Category Description' },
  { id: 'major', name: 'Major' },
  { id: 'major_description', name: 'Major Description' },
  { id: 'intermediate', name: 'Intermediate' },
  { id: 'intermediate_description', name: 'Intermediate Description' },
  { id: 'minor', name: 'Minor' },
  { id: 'minor_description', name: 'Minor Description' },
  { id: 'child_item_flag', name: 'Child Item' },
  // { id: 'fnb_status', name: 'FnB Status' },
  { id: 'mdm_item_created_time', name: 'Material Created Time' },
  { id: 'mdm_item_changed_time', name: 'Material Changed Time' },
  { id: 'created_by', name: 'Created By' },
  { id: 'updated_by', name: 'Updated By' },
  { id: 'sdp_eligibility', name: 'Supplier Visible' }
];

export const ADMIN_PERMISSION = 'MNULSLOT.ITEMS.ADMIN';
export const CANADIAN_PERMISSION = 'MNULSLOT.ITEMS.CANADIAN';
export const USBL = 'USBL';
export const CANADA = 'CANADA';
export const ALL = 'ALL';

export const OPCO_SELECTOR = [
  {
    label: 'All Regions',
    value: 'ALL'
  },
  {
    label: 'Canada',
    value: 'CANADA'
  },
  {
    label: 'USBL',
    value: 'USBL'
  }
];
