import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table, Tooltip, Tag } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import * as _ from 'lodash';

import TaxonomyDropdown from 'components/TaxonomyDropdown';
import ItemDetailsTable from 'components/ItemDetailsTable';
import TunerActionButtons from 'components/TunerActionButtons';
import * as actionCreators from '../actions';

export default function AutoSlottingTable({ items, sort, onSort }) {
  const dispatch = useDispatch();
  const { updateSlottedTable, setSelectedTableRows } = bindActionCreators(actionCreators, dispatch);

  const fetchingTaxonomies = useSelector(state => _.get(state.slottedList, 'fetchingTaxonomies', []));

  const updateItem = (supc, updates) => {
    let listClone = [...items];
    let index = _.findIndex(listClone, obj => supc === obj.supc);

    if (index !== -1) {
      let itemClone = { ...listClone[index], ...updates };
      listClone[index] = itemClone;
    }
    updateSlottedTable(listClone);
  };

  const getSortOrder = columnName => {
    const field = _.get(sort, 'param', null);
    let order = _.get(sort, 'direction', false);
    if (order === 'ASC') {
      order = 'ascend';
    } else if (order === 'DESC') {
      order = 'descend';
    }
    if (field && field === columnName) {
      return order;
    }
    return false;
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const supcs = [];
      _.forEach(selectedRows, row => {
        if (!row.confirmed) {
          supcs.push(row.supc);
        }
      });
      setSelectedTableRows(supcs);
    }
  };

  const handleDropDownChange = (type, id, name, record) => {
    let updates = {};

    if (type === 'bc') {
      if (id == null) {
        return false;
      }
      updates = { bc: id, bc_name: name, ig: null, ig_name: null, ag: null, ag_name: null };
    } else if (type === 'ig') {
      updates = { ig: id, ig_name: name, ag: null, ag_name: null };
    } else if (type === 'ag') {
      updates = { ag: id, ag_name: name };
    }

    updateItem(record.supc, updates);
  };

  const handleChanges = (a, b, newSort) => {
    let newSortObj = null;
    if (newSort && newSort.columnKey && newSort.order) {
      newSortObj = { param: newSort.columnKey, direction: newSort.order === 'ascend' ? 'ASC' : 'DESC' };
    }
    onSort(newSortObj);
  };

  const renderTaxonomyColumn = (text, record) => {
    const { bc, ig, ag, bc_name, ig_name, ag_name } = record;

    if (record.isEditing) {
      return (
        <TaxonomyDropdown
          bc={bc}
          ig={ig}
          ag={ag}
          fetchingTaxonomies={fetchingTaxonomies}
          handleChange={(type, id, name) => handleDropDownChange(type, id, name, record)}
        />
      );
    } else {
      return (
        <div className="taxo-label-wrapper">
          {bc && <div title="Business Center">{bc_name}</div>}
          {ig && (
            <React.Fragment>
              <RightOutlined className="taxo-label-arrow" />
              <div title="Item Group">{ig_name}</div>
            </React.Fragment>
          )}
          {ag && (
            <React.Fragment>
              <RightOutlined className="taxo-label-arrow" />
              <div title="Attribute Group">{ag_name}</div>
            </React.Fragment>
          )}
        </div>
      );
    }
  };

  const columns = [
    {
      title: 'SUPC',
      dataIndex: 'supc',
      key: 'supc',
      width: 100,
      render: (text, record) => {
        return (
          <Tooltip
            overlayClassName={'item-details-tooltip'}
            placement="right"
            title={<ItemDetailsTable record={record} />}
          >
            <div className="supc-column">{text}</div>
          </Tooltip>
        );
      },
      sorter: true,
      sortOrder: getSortOrder('supc')
    },
    {
      title: 'Description',
      dataIndex: 'item_description',
      key: 'item_description',
      width: 'auto',
      sorter: true,
      sortOrder: getSortOrder('item_description')
    },
    {
      title: 'Storage',
      dataIndex: 'storage_code',
      key: 'storage_code',
      width: 'auto',
      sorter: true,
      sortOrder: getSortOrder('storage_code'),
      align: 'center'
    },
    {
      title: 'Slotted At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 'auto',
      sorter: true,
      sortOrder: getSortOrder('created_at')
    },
    {
      title: 'Confidence',
      dataIndex: 'curr_id',
      key: 'curr_id',
      width: 'auto',
      sorter: true,
      sortOrder: getSortOrder('curr_id'),
      render: text => (text === 'Y' ? <Tag color="lime">Confident</Tag> : <Tag color="gold">Suspect</Tag>),
      align: 'center'
    },
    {
      title: 'Slotted To...',
      key: 'autoslotted',
      width: 665,
      render: renderTaxonomyColumn
    },
    {
      title: 'Slotting Correct?',
      key: 'confirm',
      width: 110,
      render: (text, record) => {
        return (
          <TunerActionButtons
            data={record}
            isMassEdit={false}
            isLoading={record.isLoading}
            showOkCancel={record.isEditing}
            showUndo={record.confirmed}
            updateItem={updateItem}
          />
        );
      }
    }
  ];

  return (
    <Table
      id="auto-slotting-table"
      rowKey={'supc'}
      columns={columns}
      dataSource={items}
      pagination={false}
      rowSelection={rowSelection}
      onChange={handleChanges}
    />
  );
}
